import _ from 'lodash';
import {Action, getModule, Module, Mutation, VuexModule, config} from 'vuex-module-decorators';
import { IDateRange, formatDate } from '@/core/plugins/date';

import CampaignResource from '../api/campaign.api';
import ProfileSynonymsResource from '../api/profileSynonyms.api';

import store from '@/core/store/index.store';
import toastNotificationStore from '@/core/store/toastNotification.store';
import { useAccountSettingsStore } from '@/modules/settings/store/accountSettings.store';
import { useWorkspaceStore } from '@/modules/accounts/store/workspaces.store';

import ChangeCampaignStatusDelete from '@/modules/campaigns/components/modals/ChangeCampaignStatusDelete.vue';
import ChangeCampaignStatusExtend from '@/modules/campaigns/components/modals/ChangeCampaignStatusExtend.vue';
import ChangeCampaignStatusPause from '@/modules/campaigns/components/modals/ChangeCampaignStatusPause.vue';
import ChangeCampaignStatusResume from '@/modules/campaigns/components/modals/ChangeCampaignStatusResume.vue';
import CampaignBoostedSuccessfulyModal from '@/modules/campaigns/components/modals/CampaignBoostedSuccessfulyModal.vue';
import CampaignExtendedSuccessfulyModal from '@/modules/campaigns/components/modals/CampaignExtendedSuccessfulyModal.vue';
import ChangeCampaignStatusBoost from '@/modules/campaigns/components/modals/ChangeCampaignStatusBoost.vue';
import ChangeCampaignStatusStop from '@/modules/campaigns/components/modals/ChangeCampaignStatusStop.vue';
import MissingChannelModal from '@/modules/campaigns/components/modals/MissingChannelModal.vue';
import SocialPageSelectorModal from '@/core/components/SocialPageSelectorModal.vue';

import {
  ActivityLogItem,
  Campaign,
  CampaignStatus,
  CampaignStatusAction,
  CampaignType,
  ChannelsBudget,
  CpcAndCpmItem,
  CreateCampaignParams,
  DataToExtendCampaign,
  Leadform,
} from '@/modules/campaigns/models/campaign.model';
import { AdMedia } from '@/modules/medialibrary/models/video.model';
import { CampaignGoalValue } from '@/modules/campaigns/models/campaign.goal.model';
import { Channel, MissingChannelName } from '@/modules/ads/models/ads.model';
import { getAdConfig } from '@/modules/ads';
import { getBudgetNum } from '@/modules/ads/helpers';
import { EMPLOYER_BRAND_AWARENESS, FAST_APPLY_FORM, JOB_APPLICATIONS, VACANCY_VISITS, CUSTOM } from '@/core/store/productConfig';
import { FunctionGroup } from '@/modules/campaigns/models/function.group.model';
import { handleDeployError } from '@/modules/campaigns/helpers/errorHandlers';
import { handleResponseError } from '@/core/assets/global-utils';
import { JobProfile } from '@/core/models/jobProfile.model';
import { JobProfileOption, JobProfileSynonyms } from '@/core/models/jobProfile.model';
import { Location } from '@/core/models/location.model';
import { SocialPage } from '@/modules/accounts/models/socialPage.model';
import { Workspace } from '@/modules/accounts/models/workspace.model';

config.rawError = true;

interface SearchModalState {
  isActive: boolean;
  triggerDistanceTop: number;
}

export interface ModalParams {
  isActive: boolean;
  currentComponent: object | null;
  campaign?: Campaign | null;
  socialChannel: Channel | null | MissingChannelName;
  props?: Record<string, any>;
}

export interface OpenModalParams {
  campaignAction?: CampaignStatusAction;
  campaign?: Campaign;
  props?: Record<string, any>;
}

@Module({
  name: 'campaignStore',
  namespaced: true,
  dynamic: true,
  store,
})
class CampaignStore extends VuexModule {
  public isEditing: boolean = false;
  public selectedCampaign: Campaign | null = null;
  public selectedLeadform: Leadform | null = null;
  public campaigns: Campaign[] = [];
  public functionGroups: FunctionGroup[] = [];
  public deployErrors: string[] = [];
  public loading: boolean = true;
  public activitySearchString: string = '';
  public activityLogs: ActivityLogItem[] = [];
  public jobProfiles: JobProfileSynonyms[] = [];
  public cpcAndCpm: CpcAndCpmItem | null = null;
  public labelToApiMap: Record<string, string> = {
    'Full name': 'FULL_NAME',
    'Phone': 'PHONE',
    'Email': 'EMAIL',
    'Country': 'COUNTRY',
  };
  public mainFormReference: any = null;

  get workspacesStore() {
    return useWorkspaceStore();
  }

  get goalTypes() {
    return [
      {
        type: EMPLOYER_BRAND_AWARENESS,
        tag: 'awareness',
        header: 'Employer brand awareness',
        target: 'potential candidates in a job function',
        goal: 'impressions',
      },
      {
        type: VACANCY_VISITS,
        tag: 'consideration',
        header: 'Vacancy visits',
        target: 'potential candidates that fit a specific profile',
        goal: 'clicks',
      },
      {
        type: JOB_APPLICATIONS,
        tag: 'conversions',
        header: 'Job applications',
        target: 'potential candidates that fit a specific profile',
        goal: 'conversions',
      },
      {
        type: FAST_APPLY_FORM,
        tag: 'conversions',
        header: 'Fast apply form',
        target: 'potential candidates that fit a specific profile',
        goal: 'conversions',
      },
    ];
  }

  get getCpcAndCpm() {
    return this.cpcAndCpm;
  }
  // parameter for pagination
  public pageCount: number = 1;

  public searchModal: SearchModalState = {
    isActive: false,
    triggerDistanceTop: 0,
  };

  public activeModal: ModalParams = {
    isActive: false,
    currentComponent: null,
    campaign: null,
    socialChannel: null,
    props: {},
  };

  get campaignType(): CampaignType | undefined {
    return _.get(this.selectedCampaign, 'type');
  }

  get campaignStatus(): CampaignStatus | undefined {
    return _.get(this.selectedCampaign, 'status');
  }

  get isDraftOrToApprove(): boolean {
    return ['draft', 'to approve'].includes(this.campaignStatus as string);
  }

  get isAwarenessType(): boolean {
    return this.campaignType === EMPLOYER_BRAND_AWARENESS;
  }

  get isCustomType(): boolean {
    return this.campaignType === CUSTOM;
  }

  get isFastApplyType(): boolean {
    return this.campaignType === FAST_APPLY_FORM;
  }

  get isFacebookAvailable() {
    const hasAds = getAdConfig('FacebookPostAd').store.ads.length + 
      getAdConfig('FacebookCarouselAd').store.ads.length + 
      getAdConfig('FacebookStoryAd').store.ads.length > 0;
    return this.isDraftOrToApprove || hasAds;
  }

  get isInstagramAvailable() {
    const hasAds = getAdConfig('InstagramPostAd').store.ads.length + 
      getAdConfig('InstagramCarouselAd').store.ads.length + 
      getAdConfig('InstagramStoryAd').store.ads.length > 0;
    return this.isDraftOrToApprove || hasAds;
  }

  get isGoogleDisplayAvailable() {
    const hasAds = getAdConfig('GoogleDisplayCustomAd').store.ads.length + getAdConfig('GoogleDisplayGeneratedAd').store.ads.length > 0;
    return (this.isDraftOrToApprove || hasAds) && !this.isFastApplyType;
  }

  get isGoogleSearchAvailable() {
    const hasAds = getAdConfig('GoogleSearchAd').store.ads.length > 0;
    return (this.isDraftOrToApprove || hasAds) && !this.isFastApplyType && !this.isAwarenessType;
  }

  get isLinkedinAvailable() {
    const linkedInPostAdStore = getAdConfig('LinkedinPostAd').store;
    const hasAds = linkedInPostAdStore.ads.length > 0;
    return (this.isDraftOrToApprove || hasAds) && !this.isFastApplyType;
  }

  get jobProfileOptions(): JobProfileOption[] {
    return this.jobProfiles.reduce<JobProfileOption[]>((options, currentProfile) => {
      const newOptions = currentProfile.synonyms.map((synonym) => ({ title: synonym, value: currentProfile }));
      return options.concat(newOptions);
    }, []);
  }

  get hasActiveCampaigns() {
    return this.campaignsByCurrentWorkspace.some((campaign) => {
      return campaign.status === 'active';
    });
  }

  get currencyCode() {
    const accountSettingsStore = useAccountSettingsStore();
    return accountSettingsStore.currencyCode;
  }

  get campaignsByCurrentWorkspace(): Campaign[] {
    const selectedWorkspace = this.workspacesStore.selectedWorkspace;
    if (!selectedWorkspace) {
      return [];
    }
    const selectedWorkspaceCampaignIds = selectedWorkspace.campaignIds || [];
    return this.campaigns.filter((campaign) => {
      return selectedWorkspaceCampaignIds.includes(campaign.id);
    });
  }

  get campaignsByWorkspace(): (workspace: Workspace) => Campaign[] {
    return (workspace) => {
      return this.campaigns.filter((campaign) => {
        return workspace.campaignIds.includes(campaign.id!);
      });
    };
  }

  get filteredActivity(): ActivityLogItem[] {
     return this.activityLogs.filter((activity) => {
      const { date, user, message } = activity;
      const interpolatedActivityString = `${formatDate(date, 'DD-MM-YYYY')}${user}${message}`.replace(/ /g, '').toLowerCase();
      return interpolatedActivityString.includes(this.activitySearchString.trim().replace(/ /g, '').toLowerCase());
    });
  }

  @Mutation
  public setMainFormReference(ref: any) {
    this.mainFormReference = ref;
  }

  @Mutation
  public setLoading(newLoadingState: boolean) {
    this.loading = newLoadingState;
  }

  @Mutation
  public setEditing(editFlag: boolean) {
    this.isEditing = editFlag;
  }

  @Mutation
  public setDeployErrors(errorObj: Record<string, string[]> = {}) {
    const apiToUiNameMap: Record<string, string> = {
      name: 'Form name',
      intro_headline: 'Intro headline',
      intro_description: 'Intro description',
      privacy_url: 'Privacy link',
      privacy_title: 'Privacy link text',
      completion_headline: 'Completion headline',
      completion_description: 'Completion description',
      completion_button_text: 'Completion CTA button',
      completion_button_url: 'Completion link',
      questions_description: 'Prefill questions description',
      questions: 'Questions',
      media: 'Media',
    };
    this.deployErrors = _.reduce(errorObj, (acc, val, key) => {
      const message = val.join(', ')
        .replace(/validation\.required/g, 'is missing.')
        .replace(/validation\.url/g, 'has incorrect url.')
        .replace(/validation\.distinct/g, 'cannot have two questions sharing the same field name.')
        .replace(/validation\.min\.array/g, 'should have 2 or more answers.');
      const field = (apiToUiNameMap[key] || key)
        .replace(/questions\./g, 'Question ')
        .replace(/\.label/g, '')
        .replace(/\.options/g, '');
      return [...acc, ...[`<div class="h5-subtitle mb-2"><span class="bold color-red">${field}</span> ${message}</div>`]];
    }, ['<div class="bold mb-2">Fast apply form <span class="color-red">errors:</span></div>']);
  }

  @Mutation
  public removeDeployErrors() {
    this.deployErrors = [];
  }

  @Mutation
  public setPageCount(pageCount: number) {
    this.pageCount = pageCount;
  }

  @Mutation
  public setProfiles(profiles: JobProfileSynonyms[]) {
    this.jobProfiles = profiles;
  }

  @Mutation
  public setFunctionGroups(functionGroups: FunctionGroup[]) {
    this.functionGroups = functionGroups;
  }

  @Mutation
  public setCpcAndCpm(cpcAndCpm: CpcAndCpmItem) {
    this.cpcAndCpm = cpcAndCpm;
  }

  @Mutation
  public setSelectedCampaign(campaign: Campaign | null) {
    this.selectedCampaign = campaign;
  }

  @Mutation
  public setCampaigns(campaigns: Campaign[]) { this.campaigns = campaigns; }

  @Mutation
  public setActivityLogs(activityLogs: ActivityLogItem[]) { this.activityLogs = activityLogs; }

  @Mutation
  public setSearchModal(newSearchModalState: SearchModalState) { this.searchModal = newSearchModalState; }

  @Mutation
  public setModal(newModalParams: ModalParams) {
    this.activeModal = newModalParams;
  }

  @Mutation
  public setLocations(locations: Location[]) {
    if (this.selectedCampaign !== null) { this.selectedCampaign!.locations = locations; }
  }

  @Mutation
  public appendCampaign(campaign: Campaign) { this.campaigns.push(campaign); }

  @Mutation
  public setCampaignStatus(status: CampaignStatus) {
    if (!this.selectedCampaign) {
      return;
    }
    this.selectedCampaign!.status = status;
  }

  @Mutation
  public appendJobProfile(profile: JobProfile) {
    this.selectedCampaign!.profiles.push(profile);
  }

  @Mutation
  public removeJobProfile(profile: JobProfile) {
    this.selectedCampaign!.profiles = this.selectedCampaign!.profiles.filter((item) => {
      return item.id !== profile.id;
    });
  }

  @Mutation
  public removeAllJobProfiles() {
    if (this.selectedCampaign) {
      this.selectedCampaign.profiles = [];
    }
  }

  @Mutation
  public appendLocation(location: Location) {
    this.selectedCampaign!.locations.push(location);
  }

  @Mutation
  public removeLocation(location: Location) {
    this.selectedCampaign!.locations = this.selectedCampaign!.locations.filter((item) => {
      return item !== location;
    });
  }

  @Mutation
  public setActivitySearchString(activitySearchString: string) {
    this.activitySearchString = activitySearchString;
  }

  @Mutation
  public setFunctionGroup(functionGroup: FunctionGroup) {
    this.selectedCampaign!.functionGroup = functionGroup;
  }

  @Mutation
  public removeFunctionGroup() {
    this.selectedCampaign!.functionGroup = '';
  }

  @Mutation
  public setGoal(goal: CampaignGoalValue) {
    this.selectedCampaign!.goal = goal;
  }

  @Mutation
  public setSocialPage(socialPage: SocialPage) {
    if (socialPage.channel === 'facebook') {
      this.selectedCampaign!.facebookPageId = socialPage.id;
    }
    if (socialPage.channel === 'instagram') {
      this.selectedCampaign!.instagramPageId = socialPage.id;
    }
    if (socialPage.channel === 'linkedin') {
      this.selectedCampaign!.linkedinPageId = socialPage.id;
    }
  }

  @Mutation
  public setCampaignType(type: CampaignType) {
    this.selectedCampaign!.type = type;
  }

  @Mutation
  public setCustomBudget(budget: number) {
    this.selectedCampaign!.customTotalGrossBudget = budget * 100;
  }

  @Mutation
  public setCustomChannelBudget(data: ChannelsBudget) {
    this.selectedCampaign!.customChannelBudget! = _.mapValues(data, i => i * 100);
  }

  @Mutation
  public setSchedule({ startDate, endDate }: IDateRange) {
    this.selectedCampaign!.startDate = startDate;
    this.selectedCampaign!.endDate = endDate;
  }

  @Action({commit: 'setCpcAndCpm'})
  public async updateCpcAndCpm(campaignId: string) {
    return await CampaignResource.prototype.fetchCPCAndCPMBasedOnFunctionGroup(campaignId);
  }

  @Mutation
  public setTargetLanguage(targetLanguage: string) {
    this.selectedCampaign!.language = targetLanguage;
  }

  @Mutation
  public setSelectedLeadform(leadform: Leadform) {
    this.selectedLeadform = leadform;
  }

  @Mutation
  public setSelectedLeadformMedia(media: any) {
    this.selectedLeadform!.media = {
      id: media.id,
      url: media.thumbnailUrl,
      masterUrl: media.thumbnailUrl,
      previewUrl: media.url,
      type: media.type,
    } as AdMedia;
  }

  @Action
  public updateTargetLanguage(targetLanguage: string) {
    this.context.commit('setTargetLanguage', targetLanguage);
  }

  @Action
  public updatePageCount(pageCount: number) {
    this.context.commit('setPageCount', pageCount);
  }


  @Action({ commit: 'setSelectedCampaign' })
  public selectCampaign(campaign: Campaign | null) {
    return campaign;
  }

  @Action({ commit: 'setSearchModal' })
  public openSearchModal(elementRef?: HTMLDivElement | undefined): SearchModalState {
    return {
      isActive: true,
      triggerDistanceTop: elementRef ? elementRef.getBoundingClientRect().top : 0,
    };
  }

  @Action({ commit: 'setSearchModal' })
  public closeSearchModal(): SearchModalState {
    return {
      isActive: false,
      triggerDistanceTop: 0,
    };
  }

  @Action({commit: 'setModal'})
  public openSocialPageModal(data: {socialChannel: Channel, campaign: Campaign}) {
    return {
      isActive: true,
      currentComponent: SocialPageSelectorModal,
      campaign: data.campaign,
      socialChannel: data.socialChannel,
    };
  }

  @Action({ commit: 'setModal' })
  public openMissingChannelModal(data: { socialChannel: string, campaign: Campaign }) {
    return {
      isActive: true,
      currentComponent: MissingChannelModal,
      campaign: data.campaign,
      socialChannel: data.socialChannel,
    };
  }

  @Action({ commit: 'setModal' })
  public async openChangeStatusConfirmModal({ campaignAction, campaign }: OpenModalParams): Promise<ModalParams> {
    return {
      isActive: true,
      currentComponent: selectConfirmModalByAction(campaignAction!),
      campaign,
      socialChannel: null,
    };
  }

  @Action({ commit: 'setModal' })
  public async openCampaignExtendedSuccessfullyModal(): Promise<ModalParams> {
    return {
      isActive: true,
      currentComponent: CampaignExtendedSuccessfulyModal,
      socialChannel: null,
      campaign: null,
    };
  }

  @Action({ commit: 'setModal' })
  public async openCampaignBoostededSuccessfulyModal(): Promise<ModalParams> {
    return {
      isActive: true,
      currentComponent: CampaignBoostedSuccessfulyModal,
      socialChannel: null,
      campaign: null,
    };
  }

  @Action({ commit: 'setModal' })
  public closeActiveModal(): ModalParams {
    return { isActive: false, currentComponent: null, campaign: null, socialChannel: null, props: {} };
  }

  @Action({ commit: 'setLoading' })
  public async updateLoadingState(newLoadingState: boolean): Promise<boolean> {
    return newLoadingState;
  }

  @Action({ commit: 'setProfiles' })
  public async fetchJobProfiles(): Promise<JobProfileSynonyms[]> {
    return await ProfileSynonymsResource.prototype.findAll();
  }

  @Action({commit: 'setFunctionGroups'})
  public async getFunctionGroups() {
    const functionGroups = await CampaignResource.prototype.fetchFunctionGroups();
    return functionGroups.sort();
  }

  @Action
  public resetCampaigns() {
    this.context.commit('setCampaigns', []);
  }

  @Action
  public async updateCampaignsListWithSingleCampaign(campaignId: string) {
      const updatedCampaign = await this.getSingleCampaign(campaignId);
      if (updatedCampaign) {
        const updatedList = this.campaigns.map((currentCampaign) => {
          return currentCampaign.id === updatedCampaign.id ? updatedCampaign : currentCampaign;
        });
        this.context.commit('setCampaigns', updatedList);
      } else {
        const updatedList = this.campaigns.filter((currentCampaign) => {
          return currentCampaign.id !== campaignId;
        });
        this.context.commit('setCampaigns', updatedList);
      }
  }

  @Action
  public async fetchCampaignsByWorkspace(data: { workspaceSlug: string }): Promise<Campaign[] | undefined> {
    if (this.pageCount === 1) {
      this.resetCampaigns();
    }
    try {
      const campaignsStatus = window.sessionStorage.getItem('filters') ? window.sessionStorage.getItem('filters')?.split(',') as CampaignStatus[] : [];
      const workspaceSlug = data.workspaceSlug || this.workspacesStore.selectedWorkspace?.slug || localStorage.getItem('WORKSPACE_SLUG')!;
      const campaigns = await CampaignResource.prototype.fetchCampaignsByWorskpace(workspaceSlug, String(this.pageCount), campaignsStatus);
      if (!campaigns.length) {
        return campaigns;
      }
      const campaignsToStore = campaigns.filter((campaign) => {
        return !this.campaigns.find((knownCampaign) => knownCampaign.id === campaign.id);
      });
      this.context.commit('setCampaigns', [...this.campaigns, ...campaignsToStore]);
      return campaigns;
    } catch (error) {
      console.error(error);
    } finally {
      await this.updateLoadingState(false);
    }
  }

  @Action
  public updateCampaignOnSuccessfulUpdate(campaign: Campaign) {
    const updatedList = this.campaigns.map((currentCampaign) => {
      return currentCampaign.id === campaign.id ? campaign : currentCampaign;
    });
    this.context.commit('setCampaigns', updatedList);
  }

  @Action({commit: 'setSelectedCampaign'})
  public async getSingleCampaign(campaignId: string): Promise<Campaign | null> {
    try {
      return await CampaignResource.prototype.findById(campaignId);
    } catch (error: any) {
      await handleResponseError(error);
      return Promise.resolve(null);
    }
  }

  @Action({ commit: 'setActivityLogs'})
  public async fetchActivityLogs(campaignId: string): Promise<ActivityLogItem[]> {
    return await CampaignResource.prototype.fetchAllActivityLogs(campaignId);
  }

  @Action({ commit: 'appendCampaign' })
  public async createCampaign(params: CreateCampaignParams): Promise<Campaign> {
    const createdCampaign = await CampaignResource.prototype.createNewCampaign(params);
    this.workspacesStore.selectedWorkspace!.campaignIds.push(createdCampaign.id);
    return createdCampaign;
  }

  @Action({commit: 'setSelectedCampaign'})
  public async updateCampaignFromTemplate(templateId: string) {
    const campaignId = this.selectedCampaign!.id;
    return await CampaignResource.prototype.updateCampaignFromTemplate(campaignId, templateId);
  }

  @Action
  public updateCampaignFields(campaign: Campaign): Promise<void> {
    return CampaignResource.prototype.updateCampaignFields(campaign).then(() => {
      // possibly temporary solution re-fetching campaigns from scratch on campaign update
      this.resetCampaigns();
      this.updatePageCount(1);
    });
  }

  @Action({commit: 'setSchedule'})
  public async updateSchedule({ campaign, startDate, endDate }: UpdateScheduleParams) {
    await CampaignResource.prototype.updateSchedule(campaign, startDate, endDate);
    // possibly temporary solution re-fetching campaigns from scratch on campaign update
    this.resetCampaigns();
    this.updatePageCount(1);
    return {startDate, endDate};
  }

  @Action({commit: 'setFunctionGroup'})
  public async selectFunctionGroup({campaign, functionGroup}: { campaign: Campaign, functionGroup: FunctionGroup}) {
    await CampaignResource.prototype.setFunctionGroup(campaign, functionGroup);
    return functionGroup;
  }

  @Action
  public async deleteFunctionGroup({campaign}: { campaign: Campaign}): Promise<void> {
    await CampaignResource.prototype.removeFunctionGroup(campaign);
    this.removeFunctionGroup();
  }

  @Action
  public async selectSocialPage({ id, socialPage }: { id: string, socialPage: SocialPage }) {
    await CampaignResource.prototype.selectSocialPage(id, socialPage)
      .then((response) => {
        this.context.commit('setSocialPage', socialPage);
      })
      .catch((error) => {
        handleResponseError(error);
        socialPage.id = null;
        this.context.commit('setSocialPage', socialPage);
        CampaignResource.prototype.selectSocialPage(id, socialPage);
      });
  }

  @Action({commit: 'setGoal'})
  public async selectGoal({campaign, goal}: { campaign: Campaign, goal: CampaignGoalValue}) {
    await CampaignResource.prototype.setCampaignGoal(campaign, goal);
    return goal;
  }

  @Action({commit: 'setSelectedCampaign'})
  public async duplicateCampaign(campaign: Campaign): Promise<Campaign | null> {
    const duplicatedCampaignId = await CampaignResource.prototype.duplicate(campaign);
    await this.workspacesStore.fetchWorkspaces();
    // updating list of campaigns with duplicates realtime
    const duplicatedCampaign = await this.getSingleCampaign(duplicatedCampaignId);
    this.context.commit('appendCampaign', duplicatedCampaign);
    return duplicatedCampaign;
  }

  @Action
  public async resumeCampaign(campaign: Campaign): Promise<void> {
    try {
      await CampaignResource.prototype.resume(campaign);
      await toastNotificationStore.showToastNotification({
        message: `${campaign.title} campaign activated`,
        isSuccess: true,
      });

        // Because we are using pagination it is not possible visuzlize realtime update campaign status on the page
        // thus I am using workaround
        this.campaigns.find((c) => {
          if (c.id === campaign.id) {
            c.status = 'active';
          }
        });

      this.context.commit('setCampaignStatus', newStatusByAction('resume'));
    } catch (error) {
      await toastNotificationStore.showToastNotification({
        message: `${campaign.title} failed to change status`,
        isError: true,
      });
      if (!this.selectedCampaign) {
        await this.fetchCampaignsByWorkspace({workspaceSlug: this.workspacesStore.workspaceSlug!});
      }
      throw error;
    }
  }

  @Action
  public async pauseCampaign(campaign: Campaign): Promise<void> {
    try {
      await CampaignResource.prototype.pause(campaign);
      await toastNotificationStore.showToastNotification({
        message: `${campaign.title} campaign paused`,
      });

        // Because we are using pagination it is not possible visuzlize realtime update campaign status on the page
        // thus I am using workaround
        this.campaigns.find((c) => {
          if (c.id === campaign.id) {
            c.status = 'paused';
          }
        });

      this.context.commit('setCampaignStatus', newStatusByAction('pause'));
    } catch (error) {
      await toastNotificationStore.showToastNotification({
        message: `${campaign.title} failed to change status`,
        isError: true,
        timeout: 10000,
      });
      if (!this.selectedCampaign) {
        await this.fetchCampaignsByWorkspace({workspaceSlug: this.workspacesStore.workspaceSlug!});
      }
      throw error;
    }
  }

  @Action
  public async stopCampaign(campaign: Campaign): Promise<void> {
    try {
      await CampaignResource.prototype.stop(campaign);
      await toastNotificationStore.showToastNotification({
        message: `${campaign.title} campaign stopped`,
        isError: false,
      });

        // Because we are using pagination it is not possible visuzlize realtime update campaign status on the page
        // thus I am using workaround
        this.campaigns.find((c) => {
          if (c.id === campaign.id) {
            c.status = 'stopped';
          }
        });

      this.context.commit('setCampaignStatus', newStatusByAction('stop'));
    } catch (error) {
      await toastNotificationStore.showToastNotification({
        message: `${campaign.title} failed to change status`,
        isError: true,
        timeout: 10000,
      });
      if (!this.selectedCampaign) {
        await this.fetchCampaignsByWorkspace({workspaceSlug: this.workspacesStore.workspaceSlug!});
      }
      throw error;
    }
  }

  @Action
  public async deployCampaign(campaign: Campaign): Promise<void> {
    const campaignStatus = campaign.status;
    try {
      await CampaignResource.prototype.deploy(campaign);

      // Because we are using pagination it is not possible visuzlize realtime update campaign status on the page
      // thus I am using workaround
      this.campaigns.find((c) => {
        if (c.id === campaign.id) {
          c.status = 'active';
        }
      });
      this.context.commit('setCampaignStatus', newStatusByAction('start'));
      toastNotificationStore.showToastNotification({
        message: campaignStatus === 'to approve' ?
                `${campaign.title}-campaign for ${campaign.workspaceName} is launched` :
                `${campaign.title} campaign activated`,
        isSuccess: true,
      });
      this.getSingleCampaign(campaign.id);
    } catch (error) {
      handleDeployError(error);
      throw error;
    }
  }

  @Action
  public async extendCampaign(data: DataToExtendCampaign): Promise<void | boolean> {
    try {
      await CampaignResource.prototype.extend(data);
      await this.updateCampaignsListWithSingleCampaign(data.campaign!.id);
      await this.openCampaignExtendedSuccessfullyModal();
    } catch (error: any) {
      const lowBudget = getBudgetNum(error.response.data.message);
      if (_.get(error, 'response.data.context')) {
        const context = error.response.data.context;
        const budgetPerChannel = Object.keys(context).map((key: string) => [` ${key}: requires minimum ${context[key] / 100} euro`]).join(',');
        await toastNotificationStore.showToastNotification({
          message: `${error.response.data.message}  ${budgetPerChannel}`,
          isError: true,
        });
      } else if (lowBudget === 0 || lowBudget) {
        await toastNotificationStore.showToastNotification({
          message: lowBudget ? `Not enough budget. Should be at least ${lowBudget / 100} euro` : error.response.data.message,
          isError: true,
        });
      } else {
        await toastNotificationStore.showToastNotification({
          message: `${data.campaign.title} campaign failed to change status`,
          isError: true,
        });
      }
      await this.fetchCampaignsByWorkspace({ workspaceSlug: this.workspacesStore.workspaceSlug! });
      return false;
    }
  }

  @Action
  public async boostCampaign(data: {campaign: Campaign, amount: number}) {
    try {
      await CampaignResource.prototype.boost(data.campaign, data.amount);
      await this.updateCampaignsListWithSingleCampaign(data.campaign.id);
      await this.openCampaignBoostededSuccessfulyModal();
    } catch (error: any) {
      await handleResponseError(error);
    }
  }

  @Action
  public useCustomBudget({ campaign, totalBudget, channels }: UseCustomBudgetParameters): Promise<void> {
    return CampaignResource.prototype.useCustomBudget(campaign, totalBudget, channels).then(() => {
      this.context.commit('setCustomBudget', totalBudget);
      this.context.commit('setCustomChannelBudget', channels);
      // possibly temporary solution re-fetching campaigns from scratch on campaign update
      this.resetCampaigns();
      this.updatePageCount(1);
    });
  }

  @Action({commit: 'appendLocation'})
  public async addLocation({campaign, location}: { campaign: Campaign, location: Location }) {
    await CampaignResource.prototype.addLocation(campaign, location);
    this.resetCampaigns();
    this.updatePageCount(1);
    return location;
  }

  @Action({commit: 'removeLocation'})
  public async deleteLocation({campaign, location}: { campaign: Campaign, location: Location }) {
    await CampaignResource.prototype.deleteLocation(campaign, location);
    this.resetCampaigns();
    this.updatePageCount(1);
    return location;
  }

  @Action({commit: 'appendJobProfile'})
  public async addProfile({ data, profile}: { data: Campaign, profile: JobProfile}) {
    await CampaignResource.prototype.addProfile(data, profile);
    return profile;
  }

  @Action({commit: 'removeJobProfile'})
  public async deleteProfile({ campaign, profile}: { campaign: Campaign, profile: JobProfile}) {
    await CampaignResource.prototype.deleteProfile(campaign, profile);
    return profile;
  }

  @Action({commit: 'setCampaignType'})
  public async changeCampaignType({ campaign, type }: { campaign: Campaign, type: CampaignType} ) {
    await CampaignResource.prototype.changeCampaignType(campaign, type);
    this.getSingleCampaign(campaign.id);
    return type;
  }

  @Action
  public async changeCampaignTypeToCustomWithAddedChannelBudget(data: { campaign: Campaign, channelName: string, channelAmount: number }): Promise<boolean> {
    return await CampaignResource.prototype.changeCampaignTypeToCustomWithAddedChannelBudget(data);
  }

  @Action
  public deleteCampaign(campaign: Campaign): Promise<void> {
    return CampaignResource.prototype.delete(campaign).then(() => {
      // delete one campaign from the campaigns list realtime
      const filteredCampaigns = this.campaigns.filter((knownCampaign) => {
        return knownCampaign.id !== campaign.id;
      });
      this.context.commit('setCampaigns', filteredCampaigns);
    });
  }

  @Action({ commit: 'setActivitySearchString'})
  public searchActivity(activitySearchString: string) {
    return activitySearchString;
  }

  @Action({commit: 'setSelectedLeadform'})
  public fetchLeadform(): Promise<Leadform> {
    return CampaignResource.prototype.fetchLeadform(_.get(this.selectedCampaign, 'id', ''));
  }

  @Action
  public async updateLeadform(data: any) {
    const updatedLeadform = await CampaignResource.prototype.saveLeadform({
      leadform: { ...this.selectedLeadform, ...data },
      campaignId: _.get(this.selectedCampaign, 'id', ''),
    });
    this.context.commit('setSelectedLeadform', updatedLeadform);
  }

  @Action
  public async validateFastApplyForm() {
    if (this.campaignType === FAST_APPLY_FORM) {
      CampaignResource.prototype.validateLeadform(_.get(this.selectedCampaign, 'id', ''))
        .then(() => {
          this.context.commit('removeDeployErrors');
        })
        .catch((err: Error) => {
          this.context.commit('setDeployErrors', _.get(err, 'response.data', {}));
        });
    }
  }
}

function selectConfirmModalByAction(action: CampaignStatusAction): any {
  switch (action) {
    case 'stop': return ChangeCampaignStatusStop;
    case 'pause': return ChangeCampaignStatusPause;
    case 'extend': return ChangeCampaignStatusExtend;
    case 'resume': return ChangeCampaignStatusResume;
    case 'delete': return ChangeCampaignStatusDelete;
    case 'boost': return ChangeCampaignStatusBoost;
  }
}

function newStatusByAction(action: Extract<CampaignStatusAction, 'start' | 'resume' | 'stop' | 'pause'>): CampaignStatus {
  switch (action) {
    case 'resume': case 'start': return 'active';
    case 'stop': return 'stopped';
    case 'pause': return 'paused';
  }
}

interface UpdateScheduleParams extends IDateRange {
  campaign: Campaign;
}

interface UseCustomBudgetParameters {
  campaign: Campaign;
  totalBudget: number;
  channels: ChannelsBudget;
}

export default getModule(CampaignStore, store);
